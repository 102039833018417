import { ActionTypes } from '../utils/constants'

const initialVendorState = {
    vendorDetail: {},
    isVendorDetailDialogBoxShown: false,
    vendorRegulations: [],
    regulations: []
};

const vendorReducer = (state = initialVendorState, action) => {
    switch (action.type) {
        case ActionTypes.vendorDetail:
            return {
                ...state,
                vendorDetail: action.vendorDetail
            };
        case ActionTypes.isVendorDetailDialogBoxShown:
            return {
                ...state,
                isVendorDetailDialogBoxShown: action.isVendorDetailDialogBoxShown
            };
        case ActionTypes.vendorRegulations:
            {

                return {
                    ...state,
                    vendorRegulations: action.payloads.vendorRegulations,
                    regulations: action.payloads.regulations
                };
            }
        default:
            return state;
    }
}

export default vendorReducer;