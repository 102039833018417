import * as React from 'react';
import { connect } from "react-redux";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';




const MultiSelector = (props) => {

    const { data, defaultValue, isLoading } = props;


    const { onChange, onSelectedChange } = props;
    const [dataValue, setDataValue] = React.useState([]);

    const listNoDataRender = (element) => {
        const noData = (
            <span />
        );

        return React.cloneElement(element, { ...element.props }, noData);
    }
    useEffect(() => {
        setDataValue(data);

    }, [data]);


    return (
        <React.Fragment >
            <div className="example-wrapper">
                <div>

                    <MultiSelect
                        data={dataValue}
                        defaultValue={defaultValue}
                        filterable={true}
                        onFilterChange={onChange}
                        loading={isLoading}
                        onChange={onSelectedChange}
                        opened={
                            dataValue.length > 0 ? true : false}
                        autoClose={true}
                        listNoDataRender={listNoDataRender}



                    />
                </div>
            </div>

            <style>{`


    .example-wrapper { 
        
        align-content: flex-start; 
        min-width :20%;
        padding-bottom:10%;
        padding-top:0%;
        padding-left:2%;
         padding-right:2%;
    }
    .example-wrapper p, .example-col p {
        margin: 20px 0 10px; 
    }
    .example-wrapper p:first-child, .example-col p:first-child { 
        margin-top: 0; 
    }

`}
            </style>

        </React.Fragment >
    );

}


const mapStateProps = (state, ownProps) => {
    return {
        data: ownProps.data,
        defaultValue: ownProps.defaultValue,
        isLoading: ownProps.isLoading
    };
};

const dispatchToFilterMethods = (dispatch, ownProps) => {
    return {
        onChange: (event) => ownProps.onChange(event,ownProps.filterType),
        onSelectedChange: (event) => ownProps.onSelectedChange(event)
    };
};
export default connect(mapStateProps, dispatchToFilterMethods)(MultiSelector);

