import { AjaxAPI } from '../../../utils/ajaxAPI';
import { ItemsApi } from '../utils/urlConstant';
import { ActionTypes } from '../utils/constants';
import { ExceptionHandler } from '../../../utils/exceptionHandler';
import {CloseLoaderIndicator} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';

export function FetchItems(data) {
    return async function (dispatch) {

        const ajaxCalls = new AjaxAPI();
        const url = ItemsApi();
        const response = await ajaxCalls.postMethod(url, data);

        if (response.ok) {
            const json = await response.json();
            CloseLoaderIndicator();

            return dispatch(dispatchItems(json));
        }

        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);

        CloseLoaderIndicator();
        return [];
    }
}

const dispatchItems = (items) => {
    return {
        type: ActionTypes.items, items
    }
}



