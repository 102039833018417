
export const MapStateToLoaderIndicatorProps = state => {
    return {
        isOpenLoader: state.loaderIndicatorReducer.isOpenLoader
    };
};

export const MapDispatchToLoaderIndicatorProps = dispatch => {
    return {

    }
};


