import React from "react";
import '@progress/kendo-theme-material/dist/all.css';
import "./App.css"
import HomePage from "./home/components/index";
import { Provider } from "react-redux";
import store from "./store/index";

const App = () => (
  <Provider store={store}>
    <HomePage />
  </Provider>
);
export default App;