
import { ActionTypes } from '../utils/constants'

const initialState = {
    isSuccess: false,
    isError: false,
    message: ""
};

const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.clearMessage:
            return {
                ...state,
                isSuccess: false,
                isError: false,
                message: ""
            };
        case ActionTypes.successMessage:
            return {
                ...state,
                isSuccess: true,
                isError: false,
                message: action.message
            };
        case ActionTypes.errorMessage:
            return {
                ...state,
                isSuccess: false,
                isError: true,
                message: action.message
            };

        default:
            return state;
    }
}

export default alertReducer;