import React from "react";


import {
    TreeList, mapTree, extendDataItem, filterBy, orderBy
} from '@progress/kendo-react-treelist';
import LoaderComponent from "./loader";


const expandField = 'expanded';

const TreeListComponent = (props) => {

    const { treeDataItems, subItemsField, expanded, treeDataColumns, setDataState, dataState,
        handleExpandChange, cellRender } = props;

    const addExpandField = (dataTree) => {
        return mapTree(dataTree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: expanded.includes(item.id)
            })
        );
    }


    const processData = (event) => {
        let filteredData = filterBy(treeDataItems, dataState.filter, subItemsField)
        let sortedData = orderBy(filteredData, dataState.sort, subItemsField)
        return sortedData.length > 0 ? addExpandField(sortedData) : [];
    }

    const handleDataStateChange = (event) => {
        setDataState(event.data);
    }

    if (treeDataItems.length === 0) {
        return (<LoaderComponent />)
    } else {
        return (
            <TreeList
                style={{ margin: '0px,0px', maxHeight: 'auto', overflow: 'auto', 'borderColor': 'transparent', 'fontSize': 'small' }}
                data={processData()}
                {...dataState}
                onDataStateChange={handleDataStateChange}
                columns={treeDataColumns}
                expandField={expandField}
                subItemsField={subItemsField}
                onExpandChange={handleExpandChange}
                cellRender={cellRender}
            />
        );
    }
}

export default TreeListComponent;

