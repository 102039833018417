import { DispatchAlertError } from '../shared-components/alerts/actionCreators/alertAction';

export class ExceptionHandler {

    async ShowErrorAlert(response) {
        DispatchAlertError(await this.GetAPIErrorMessage(response));
    }

    async GetAPIErrorMessage(response) {
        if (response.status === 404) {
            return response.statusText;
        }

        let errorMessage = "";
        try {
            const exceptionJson = await response.json();

            if (exceptionJson.ValidationErrors && exceptionJson.ValidationErrors.length > 0) {
                errorMessage = exceptionJson.ValidationErrors[0].ErrorMessage;
            }
            else {
                errorMessage = exceptionJson.StatusText;
                if (exceptionJson.ErrorMessage && exceptionJson.ErrorMessage.trim().length > 0) {
                    errorMessage += " - " + exceptionJson.ErrorMessage;
                }
            }

        } catch (e) {
            errorMessage = response.statusText
        }

        return errorMessage;
    }
}