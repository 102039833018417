import React from 'react';
const LoaderComponent = props => {
    return (
        <div style={{ position: 'absolute', top: "50%", width: '100%' }}>
            <div style={{ position: 'absolute', width: '100%' }}>
                <div className="k-loading-image" />
            </div>
        </div>
    );
}

export default LoaderComponent;


