import * as React from "react";
import { Button } from '@progress/kendo-react-buttons';

export const GridActionCell = props => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];

  return inEdit ? (
    <td className="k-command-cell">

      <Button title="save" onClick={() => props.update(dataItem)} icon="save" primary={true}></Button>
      <Button title="cancel" icon="reload" onClick={() => props.cancel(dataItem)}></Button>

    </td>
  ) : (
    <td className="k-command-cell">

      <Button
        title="Edit"
        disabled={props.buttonDisable}
        onClick={() => {
          props.edit(dataItem)
        }} icon="pencil" primary={true}></Button>
    </td>
  );
};
