import { SearchEngineActionTypes } from '../utils/constants'

const initialFilterState = {
    seriesFilters: [],
    marketFilters: [],
    collectiveClassFilters: [],
    officeFilters: [],
    isReset: false
};

const searchEngineReducer = (state = initialFilterState, action) => {
    switch (action.type) {
        case SearchEngineActionTypes.marketFilters:
            return {
                ...state, marketFilters: action.marketFilters
            };
        case SearchEngineActionTypes.seriesFilters:
            return {
                ...state, seriesFilters: action.seriesFilters
            };
        case SearchEngineActionTypes.collectiveClassFilters:
            return {
                ...state, collectiveClassFilters: action.collectiveClassFilters
            };
        case SearchEngineActionTypes.officeFilters:
            return {
                ...state, officeFilters: action.officeFilters
            };
        case SearchEngineActionTypes.isReset:
            return {
                ...state, isReset: action.isReset
            };
        default:
            return state;
    }
}

export default searchEngineReducer;