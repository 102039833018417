
export const MapStateToHomeProps = (state, ownProps) => {
    return {
        isAlertShow: state.alertReducer.isSuccess || state.alertReducer.isError
    };
};

export const MapDispatchToHomeProps = (dispatch, ownProps) => {
    return {
    };
};
