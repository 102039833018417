import React from 'react';
import { connect } from "react-redux";
import { Loader } from '@progress/kendo-react-indicators';
import { MapStateToLoaderIndicatorProps, MapDispatchToLoaderIndicatorProps } from '../utils/loaderPropMapping';


const LoaderIndicatorComponent = props => {

    const { isOpenLoader } = props;

    return (
        <React.Fragment>
            {
                isOpenLoader &&
                <div className='row' className="k-overlay" style={{ backgroundColor: "#fff", position: 'absolute', width: '100%' }}>
                    <div className='col-12' style={{ left: "50%", top: "50%", position: 'absolute', width: '50%' }}>
                        <Loader type='converging-spinner' />
                    </div>
                </div>

            }
        </React.Fragment>
    );
}

export default connect(MapStateToLoaderIndicatorProps, MapDispatchToLoaderIndicatorProps)(LoaderIndicatorComponent);
