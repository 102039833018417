export const TITLE = "Regulatory Dashboard";

export const ITEMS_TITLE = "Item";
export const COMPLAINCE_TITLE = "Compliance";
export const COLLECTIVE_CLASS_TITLE = "Collective Class";
export const SERIES_TITLE = "Series";
export const MARKET_TITLE = "Market";
export const OFFICE_TITLE = "Office";
export const VENDOR_NAME_TITLE = "Vendor Name";
export const VENDOR_DETAIL_TITLE = "Vendor Detail";
export const ETD_TITLE = "ETD";
export const ETA_TITLE = "ETA";
export const DESCRIPTION_TITLE = "Description";

export const COMPLAINT = "Compliant";
export const COMING_DUE = "Coming Due";
export const EXPIRED = "Expired/Non Compliant";
export const NOT_UPDATED = "Not Updated";
export const REGULATORY_MATRIX = "Regulatory Matrix";
export const COMMUNICATIONS = "Communications";
export const VENDOR_MANAGEMENT = "Vendor Management";
export const TESTING = "Testing";
export const REGULATORY_MANAGEMENT = "Regulatory Management";
export const DASHBOARD = "Dashboard";

export const RegulationResults =
{
    compliant: 1,
    expired: 2,
    notUpdated: 3,
    comingDue: 4,
    notApplicable: 5

}

export const NOT_APPLICABLE = "NA";
export const SITE = "site";
export const SITE_VENDOR = "Site / Vendor";
export const VENDOR = "vendor"
export const ITEM_SKU = "ItemSKU"

export const SortOrders =
{
    ascending: "asc",
    descending: "desc",
}