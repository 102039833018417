import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { MapStateToAlertProps, MapDispatchToAlertProps } from "../utils/alertPropMapping";

const AlertComponent = props => {
    const { isSuccess, isError, message } = props;
    const { DispatchAlertClear } = props;

    useEffect(() => {
        if (isError || isSuccess) {
            setTimeout(DispatchAlertClear, 4000);
        }
    }, [isSuccess, isError]);

    return (
        <React.Fragment>

            <NotificationGroup
                style={{
                    right: 0,
                    bottom: 0,
                    alignItems: 'flex-start',
                    flexWrap: 'wrap-reverse',
                    zIndex:1000,
                }}
            >
                <Fade enter={true} exit={true}>
                    {isSuccess && <Notification
                        type={{ style: 'success', icon: true }}
                        closable={true}
                        onClose={DispatchAlertClear}
                    >
                        <span>{message}</span>
                    </Notification>}
                </Fade>
                <Fade enter={true} exit={true}>
                    {isError && <Notification
                        type={{ style: 'error', icon: true }}
                        closable={true}
                        onClose={DispatchAlertClear}
                    >
                        <span>{message}</span>
                    </Notification>}
                </Fade>
            </NotificationGroup>

            <style>{`
            .k-notification-container {
    margin: 10px;
} `}
            </style>
        </React.Fragment>
    );

}

export default connect(MapStateToAlertProps, MapDispatchToAlertProps)(AlertComponent);