
import { ActionTypes } from '../utils/constants';
import store from '../../../store/index';

export const DispatchAlertClear = () => {
    return {
        type: ActionTypes.clearMessage
    };
}

export const DispatchAlertSuccess = message => {
    store.dispatch({
        type: ActionTypes.successMessage,
        message: message
    });
}

export const DispatchAlertError = message => {
    store.dispatch({
        type: ActionTypes.errorMessage,
        message: message
    });
}