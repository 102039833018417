import { AjaxAPI } from '../../../utils/ajaxAPI';
import { SiteVendorRegulationApi, SiteItemRegulationApi } from '../utils/urlConstant';
import { RegulationResults, SITE, VENDOR } from '../../../utils/appStringConstants';
import { ExceptionHandler } from '../../../utils/exceptionHandler';


export async function FetchComponentVendorRegulations(itemId, id, itemDetailId) {

    const url = SiteItemRegulationApi(itemId, itemDetailId);
    const ajaxCalls = new AjaxAPI();
    const response = await ajaxCalls.getMethod(url);

    if (!response.ok) {
        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);
        return [];
    }

    const itemComponents = [];
    let count = 0;

    const json = await response.json();

    json.componentRegulationCompliances.forEach(itemComponent => {
        count = count + 1;
        const data = {
            id: id + "-" + count,
            itemDetailId: itemComponent.ItemComponentId,
            itemDetailName: itemComponent.componentItemNumber,
            itemDetailType: 'Item Component',
            regulations: null
        };
        itemComponent.regulations && itemComponent.regulations.forEach(regulationItem => {
            data[regulationItem.regulationId] = regulationItem.compliant;
        });

        let componentVendors = [];
        let vendorComponentCount = 0;
        itemComponent.vendorRegulationCompliances && itemComponent.vendorRegulationCompliances.forEach(componentVendorItem => {
            vendorComponentCount = vendorComponentCount + 1;
            const regulations = {
                id: id + "-" + count + "-" + vendorComponentCount,
                itemDetailId: componentVendorItem.itemVendorId,
                itemDetailName: componentVendorItem.vendorNumber,
                itemDetailType: 'Vendor Item Component',
                additionaDetails: {
                    etd: componentVendorItem.etd,
                    eta: componentVendorItem.eta,
                    vendorName: componentVendorItem.vendorName,
                    office: componentVendorItem.office
                }
            };
            componentVendorItem.regulations && componentVendorItem.regulations.forEach(componentVendorRegulationItem => {
                regulations[componentVendorRegulationItem.regulationId] = componentVendorRegulationItem.compliant
            });
            componentVendors.push(regulations)
        });
        data.regulations = componentVendors;
        itemComponents.push(data);
    });

    return itemComponents;

}

export async function FetchSiteVendorRegulations(itemId) {
    const ajaxCalls = new AjaxAPI();
    const url = SiteVendorRegulationApi(itemId);
    const response = await ajaxCalls.getMethod(url);

    if (!response.ok) {
        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);
        return {
            siteVendorRegulations: [],
            regulations: []
        };
    }

    const regulations = [];
    const json = await response.json();

    json.siteRegulationCompliances && json.siteRegulationCompliances.length > 0 && json.siteRegulationCompliances[0].regulations.forEach(regulationItem => {
        const siteRegulation = {
            regulationId: regulationItem.regulationId,
            regulationName: regulationItem.regulationName
        };

        const itemIndex = regulations.findIndex(val => siteRegulation.regulationId === val.regulationId);
        if (itemIndex === -1) {
            regulations.push(siteRegulation)
        }
    });

    json.vendorRegulationCompliances && json.vendorRegulationCompliances.length > 0 && json.vendorRegulationCompliances[0].regulations.forEach(regulationItem => {
        const vendorRegulation = {
            regulationId: regulationItem.regulationId,
            regulationName: regulationItem.regulationName
        };

        const itemIndex = regulations.findIndex(val => vendorRegulation.regulationId === val.regulationId);

        if (itemIndex === -1) {
            regulations.push(vendorRegulation);
        }
    });

    const siteVendorRegulation = [];
    let count = 0;

    json.siteRegulationCompliances && json.siteRegulationCompliances.forEach(site => {
        count = count + 1;
        const sites = {
            id: count.toString(),
            itemDetailId: site.siteId,
            itemDetailName: site.siteName,
            itemDetailType: SITE,
            regulations: [{}]
        };

        regulations.forEach(regulation => {

            const itemIndex = site.regulations.findIndex(val => regulation.regulationId === val.regulationId);

            if (itemIndex === -1) {
                sites[regulation.regulationId.toString()] = RegulationResults.notApplicable;
            }
            else {
                sites[regulation.regulationId] = site.regulations[itemIndex].compliant;
            }
        });
        siteVendorRegulation.push(sites);

    });
    json.vendorRegulationCompliances && json.vendorRegulationCompliances.forEach(vendor => {
        count = count + 1;
        const vendors = {
            id: count.toString(),
            itemDetailId: vendor.itemVendorId,
            itemDetailName: vendor.vendorNumber,
            itemDetailType: VENDOR,
            additionaDetails: {
                etd: vendor.etd,
                eta: vendor.eta,
                vendorName: vendor.vendorName,
                office: vendor.office
            }
        };

        regulations.forEach(regulation => {

            const itemIndex = vendor.regulations.findIndex(val => regulation.regulationId === val.regulationId);

            if (itemIndex === -1) {
                vendors[regulation.regulationId.toString()] = RegulationResults.notApplicable;
            }
            else {
                vendors[regulation.regulationId.toString()] = vendor.regulations[itemIndex].compliant;
            }
        });

        siteVendorRegulation.push(vendors);
    });

    return {
        siteVendorRegulations: siteVendorRegulation,
        regulations: regulations
    };
}

