import { FetchItems } from "../actionCreators/itemAction"
export const MapStateToItemProps = (state, ownProps) => {
    return {
        items: state.itemReducer.items, 
        seriesFilters: state.searchEngineReducer.seriesFilters,
        marketFilters: state.searchEngineReducer.marketFilters,
        officeFilters: state.searchEngineReducer.officeFilters,
        collectiveClassFilters: state.searchEngineReducer.collectiveClassFilters,
        isfiltered: state.itemReducer.isfiltered,
        isReset: state.searchEngineReducer.isReset
    };
};
export const MapDispatchToItemProps = (dispatch, ownProps) => {

    return {
        dispatchItems: async (data) => dispatch(FetchItems(data)),
        handleFilterClick: () => ownProps.handleFilterClick()
    }
};




