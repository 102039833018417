import { ActionTypes } from '../utils/constants'

const initialItemsState = {
    regulations: [],
};

const regulationReducer = (state = initialItemsState, action) => {
    switch (action.type) {
        case ActionTypes.regulations:
            return {
                ...state,
                regulations: action.regulations
            };
        default:
            return state;
    }
}

export default regulationReducer;