import React, { useState } from "react";
import { connect } from "react-redux";
import { filterBy } from '@progress/kendo-data-query';
import { FetchFilters } from '../actionCreators/searchEngineAction';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import MultiSelector from '../../../shared-components/shared-controls/multiSelector';
import { MapDispatchToFilterProps, MapStateToFilterProps } from "../../searchEngine/utils/searchEnginePropsMapping";
import { GridProperties } from "../../dashboard/utils/constants";
import { ITEM_SKU, SortOrders, SERIES_TITLE, MARKET_TITLE, OFFICE_TITLE, COLLECTIVE_CLASS_TITLE } from '../../../utils/appStringConstants';
import { MAX_SERIES_SEARCH_TEXT, SearchFilters, DELAY } from '../utils/constants';
import {
    ShowLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';

const SearchEngine = (props) => {
    const { dispatchItemsByFilter } = props;
    const { dispatchSeriesFilters,dispatchMarketFilters,
        dispatchCollectiveClassFilters ,dispatchOfficeFilters } = props;
    const { dispatchResetPagination } = props;
    const { seriesFilters, officeFilters, collectiveClassFilters, marketFilters } = props;
    const [seriesData, setSeriesData] = useState([]);
    const [officeData, setOfficeData] = useState([]);
    const [marketData, setMarketData] = useState([]);
    const [collectiveClassData, setCollectiveClassData] = useState([]);
    const [isSeriesLoading, setIsSeriesLoading] = useState(false);
    const [isOfficeLoading, setIsOfficeLoading] = useState(false);
    const [isMarketLoading, setIsMarketLoading] = useState(false);
    const [isCollectiveClassLoading, setIsCollectiveClassLoading] = useState(false);

    const clearFilterData = (value, changeFrom) => {
        if (changeFrom === SearchFilters.Series) {
            if (value.length === 0 && seriesData.length > 0) {
                setSeriesData([]);
            }
        }
        else if (changeFrom === SearchFilters.Market) {
            if (value.length === 0 && marketData.length > 0) {
                setMarketData([]);
            }
        }
        else if (changeFrom === SearchFilters.Office) {
            if (value.length === 0 && officeData.length > 0) {
                setOfficeData([]);
            }
        }
        else if (changeFrom === SearchFilters.CollectiveClass) {
            if (value.length === 0 && collectiveClassData.length > 0) {
                setCollectiveClassData([]);
            }
        }
    }

    const fetchFilters = (text, changeFrom) => {
        EnableLoaders(changeFrom);
        FetchFilters(text, changeFrom).then((value) => {
            mapFilterData(value, changeFrom);
        });
    }

    const EnableLoaders = (changeFrom) => {
        if (changeFrom === SearchFilters.Series) {
            setIsSeriesLoading(true);
        }
        else if (changeFrom === SearchFilters.Market) {
            setIsMarketLoading(true);
        }
        else if (changeFrom === SearchFilters.Office) {
            setIsOfficeLoading(true);
        }
        else if (changeFrom === SearchFilters.CollectiveClass) {
            setIsCollectiveClassLoading(true);
        }
    }

    const mapFilterData = (value, changeFrom) => {
        if (changeFrom === SearchFilters.Series) {
            setSeriesData(value);
            setIsSeriesLoading(false);
        }
        else if (changeFrom === SearchFilters.Market) {
            setMarketData(value);
            setIsMarketLoading(false);
        }
        else if (changeFrom === SearchFilters.Office) {
            setOfficeData(value);
            setIsOfficeLoading(false);
        }
        else if (changeFrom === SearchFilters.CollectiveClass) {
            setCollectiveClassData(value);
            setIsCollectiveClassLoading(false);
        }
    }

    const handleFilterChange = (event, changeFrom) => {
        let searchTimer = null;
        clearFilterData(event.filter.value, changeFrom);
        if (searchTimer) {
            clearTimeout(searchTimer);
            searchTimer = null;
        }
        if (event.filter.value.length > MAX_SERIES_SEARCH_TEXT && seriesData.length === 0) {
            searchTimer = setTimeout(function () {
                clearTimeout(searchTimer);
                searchTimer = null;
                fetchFilters(event.filter.value, changeFrom);
            }, DELAY);
        }
        else if (event.filter.value.length > MAX_SERIES_SEARCH_TEXT && seriesData.length > 0) {
            const filtered = filterBy(seriesData, event.filter);
            mapFilterData(filtered, changeFrom);
        }
    }

    const handleSelectedOfficeFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchOfficeFilters(event.target.value ? [...event.target.value] : []);
    }
  
    const handleSelectedClassFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchCollectiveClassFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedSeriesFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchSeriesFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedMarketFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchMarketFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSearch = (event) => {
        event.preventDefault();
        ShowLoaderIndicator();
        let data = {
            "pageNo": GridProperties.startIndex,
            "pageSize": GridProperties.endIndex,
            "orderBy": ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": []
        }
        if (seriesFilters && seriesFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Series,
                searchText: seriesFilters
            });
        } if (marketFilters && marketFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Market,
                searchText: marketFilters
            });
        }
        if (officeFilters && officeFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Office,
                searchText: officeFilters
            });
        }
        if (collectiveClassFilters && collectiveClassFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.CollectiveClass,
                searchText: collectiveClassFilters
            });
        }
        dispatchResetPagination(true);
        dispatchItemsByFilter(data);

    }

    return (
        <React.Fragment>
            <div>

                <div className="col-xs-12 col-sm-12 example-col">


                    <div className="panelbar-wrapper panel-item">
                        <PanelBar  >

                            <PanelBarItem expanded={false} title={SERIES_TITLE}>
                                <MultiSelector
                                    data={seriesData}
                                    onChange={handleFilterChange}
                                    filterType={SearchFilters.Series}
                                    isLoading={isSeriesLoading}
                                    onSelectedChange={handleSelectedSeriesFilterChange}
                                    defaultValue={seriesFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={false} title={MARKET_TITLE} >
                                <MultiSelector
                                    data={marketData}
                                    onChange={handleFilterChange}
                                    filterType={SearchFilters.Market}
                                    isLoading={isMarketLoading}
                                    onSelectedChange={handleSelectedMarketFilterChange}
                                    defaultValue={marketFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={false} title={COLLECTIVE_CLASS_TITLE} >
                                <MultiSelector
                                    data={collectiveClassData}
                                    onChange={handleFilterChange}
                                    isLoading={isCollectiveClassLoading}
                                    filterType={SearchFilters.CollectiveClass}
                                    onSelectedChange={handleSelectedClassFilterChange}
                                    defaultValue={collectiveClassFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={false} title={OFFICE_TITLE} >
                                <MultiSelector
                                    data={officeData}
                                    onChange={handleFilterChange}
                                    isLoading={isOfficeLoading}
                                    filterType={SearchFilters.Office}
                                    onSelectedChange={handleSelectedOfficeFilterChange}
                                    defaultValue={officeFilters}
                                />
                            </PanelBarItem>

                        </PanelBar>
                    </div>

                </div>
                <p >
                    <Button className="search-btn" style={{ "background-color": "#CCCCCC", padding: "1% 3%" }} onClick={handleSearch} >
                        Search
                    </Button> </p>
            </div>
           
            <style>{`
            .search-btn{
              
               margin-left: 65% ;               
               "background-color": "#CCCCCC";
            }

            .panel-item{
               padding-Top: 10%; 
               padding-Bottom: 20%;
               margin-left: 0% ;
            }
            `}
            </style>
        </React.Fragment >
    );
}


export default connect(MapStateToFilterProps, MapDispatchToFilterProps)(SearchEngine);

