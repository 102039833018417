import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import GridComponent from '../../../shared-components/shared-controls/grid'
import { COMPLAINT, COMING_DUE, EXPIRED, NOT_UPDATED, ITEM_SKU, SortOrders } from '../../../utils/appStringConstants';
import { MapDispatchToItemProps, MapStateToItemProps } from "../utils/itemPropMapping";
import { Button } from '@progress/kendo-react-buttons';

import SiteVendorComponent from "./siteVendorComponent";
import {
    ITEMS_TITLE, COMPLAINCE_TITLE, COLLECTIVE_CLASS_TITLE, MARKET_TITLE, OFFICE_TITLE, ETD_TITLE, ETA_TITLE,
    DESCRIPTION_TITLE, RegulationResults
} from '../../../utils/appStringConstants';
import {
    ITEMS, COMPLAINCE, COLLECTIVE_CLASS, MARKET, OFFICE, ETD, ETA, DESCRIPTION, GridProperties, GridPageProperties
} from '../utils/constants'
import { FetchSiteVendorRegulations } from '../actionCreators/siteVendorAction';
import {
    ShowLoaderIndicator, CloseLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';
import { SearchFilters } from "../../searchEngine/utils/constants";

const CellWithBackGround = (props) => {

    const complaince = props.dataItem[props.field];
    let color1 = "rgb(55, 180, 0,0.32)";
    let color2 = "rgb(243, 23, 0, 0.32)";
    let color3 = "#CCCCCC";
    let color4 = "#D4D756";
    let cellColor = "rgba(0, 0, 0, 0.12)";
    if (complaince === RegulationResults.compliant) {
        cellColor = color1;
    } else if (complaince === RegulationResults.expired) {
        cellColor = color2;
    } else if (complaince === RegulationResults.notUpdated) {
        cellColor = color3;
    } else if (complaince === RegulationResults.comingDue) {
        cellColor = color4;
    }

    const shapeStyle = {
        fill: cellColor,
        stroke: "gray",
        'strokeWidth': "1",
        opacity: "1"
    }
    return (
        <td >
            <svg width="100" height="30">
                <rect x="0" y="0" rx="0" ry="0" width="100" height="30"
                    style={shapeStyle} />
            </svg>
        </td>
    );
}

const HeaderCellWithCustomStyle = (props) => {
    const headerStyle = {
        fontSize: "14px",
    }
    return (
        <span style={headerStyle}>{props.title}</span>
    );
}


const Items = props => {
    const { items, seriesFilters, officeFilters, collectiveClassFilters, marketFilters, isfiltered, isReset } = props;
    const { dispatchItems } = props;
    const { handleFilterClick } = props;
    const gridColumns = [
        { field: ITEMS, title: ITEMS_TITLE, expandable: true, headerCell: HeaderCellWithCustomStyle },
        { field: COMPLAINCE, title: COMPLAINCE_TITLE, cell: CellWithBackGround, headerCell: HeaderCellWithCustomStyle },
        { field: COLLECTIVE_CLASS, title: COLLECTIVE_CLASS_TITLE, headerCell: HeaderCellWithCustomStyle },
        { field: MARKET, title: MARKET_TITLE, headerCell: HeaderCellWithCustomStyle },
        { field: OFFICE, title: OFFICE_TITLE, headerCell: HeaderCellWithCustomStyle },
        { field: ETA, title: ETA_TITLE, headerCell: HeaderCellWithCustomStyle },
        { field: ETD, title: ETD_TITLE, headerCell: HeaderCellWithCustomStyle },
        { field: DESCRIPTION, title: DESCRIPTION_TITLE, headerCell: HeaderCellWithCustomStyle }
    ];

    const [gridData, setGridData] = useState([]);
    const [dataState, setDataState] = useState({});

    const gridDiv = useRef(null);
    const handleDataStateChange = (event) => {
        ShowLoaderIndicator();
        let data = {
            "pageNo": event.data.skip = 0 ? 0 : parseInt(event.data.skip / event.data.take),
            "pageSize": event.data.take,
            "orderBy": ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": []
        }
        if (seriesFilters && seriesFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Series,
                searchText: seriesFilters
            });
        } if (marketFilters && marketFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Market,
                searchText: marketFilters
            });
        }
        if (officeFilters && officeFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Office,
                searchText: officeFilters
            });
        }
        if (collectiveClassFilters && collectiveClassFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.CollectiveClass,
                searchText: collectiveClassFilters
            });
        }
        dispatchItems(data);
        event.data.skip = (event.data.skip * event.data.take);
        setDataState(event.data);
    }

    const handleExpandChange = async (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined ?
                event.dataItem.aggregates : event.dataItem.expanded;

        event.dataItem.expanded = !isExpanded;

        const gridItems = gridData.slice();

        const index = gridItems.findIndex(d => d.itemId === event.dataItem.itemId);

        if (event.value && !gridItems[index].details) {
            ShowLoaderIndicator();
            gridItems[index].details = await FetchSiteVendorRegulations(event.dataItem.itemId);
            CloseLoaderIndicator();
        }
        setGridData(gridItems);
    }

    useEffect(() => {
        ShowLoaderIndicator();
        let data = {
            "pageNo": GridProperties.startIndex,
            "pageSize": GridProperties.endIndex,
            "orderBy": ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": []
        }
        dispatchItems(data);
    }, []);

    useEffect(() => {

        setGridData((props.items?.data) ?? []);


    }, [props.items]);
    useEffect(() => {
        if (isReset) {
            const gridDataState = {
                skip: 0,
                take: 10
            }
            setDataState(gridDataState);
        }
    }, [isReset])

    let iconColor = isfiltered ? "red" : "grey";

    return (
        <React.Fragment>
            {(gridData.length > 0) &&

                <div className="row grid-legends">

                    <button className="k-button  btn-not-updated" >{NOT_UPDATED}</button>&nbsp;
                <button className="k-button btn-complaint" >{COMPLAINT}</button> &nbsp;
                <button className="k-button btn-coming-due" >{COMING_DUE}</button> &nbsp;
                <button className="k-button btn-expired" >{EXPIRED}</button>
                    <Button icon="k-icon  k-i-filter k-icon-64" togglable={true} look="clear"
                        style={{ color: iconColor, left: "0", paddingLeft: "1%", position: "absolute" }}
                        onClick={handleFilterClick} className="filter-icon"></Button>
                </div>
            }

            {
                <div ref={gridDiv}>
                    <GridComponent
                        gridColumns={gridColumns}
                        total={(items?.totalCount) ?? 0}
                        gridData={gridData}
                        dataState={dataState}
                        detail={SiteVendorComponent}
                        expandField="expanded"
                        onExpandChange={handleExpandChange}
                        onDataStateChange={handleDataStateChange}
                        gridProperties={GridProperties}
                        gridPageProperties={GridPageProperties}
                    />
                </div>
            }

            <style>{`
            .grid-legends{
               padding-Top: 0.5%; 
               padding-Bottom: 0.5%;
               margin-left: 35% 
            }
            .btn-not-updated {
                background-color: #CCCCCC;
                
                
            }
            .btn-complaint {
                background-color: rgb(55, 180, 0,0.32);
            }
             .btn-coming-due {
                background-color: #D4D756;
            }
             .btn-expired {
                background-color: rgb(243, 23, 0, 0.32);
            } 
             
         .filter-icon{
             color: "grey";
              left: "0"; paddingLeft: "2%";
               position: "absolute";
         }
            `}
            </style>
        </React.Fragment >

    );
}

export default connect(MapStateToItemProps, MapDispatchToItemProps)(Items);


