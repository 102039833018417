import { API_URL } from "../../../utils/apiConfig";

const ITEMS_API = 'regulations/items';
const SITE_ITEM_REGULATION_API = 'regulations/items/<itemId>/sites/<siteId>';
export const ItemsApi = () => {
    return API_URL + ITEMS_API;
}

export const SiteVendorRegulationApi = (itemId) => {
    return API_URL + ITEMS_API + "/" + itemId.toString();

}

export const SiteItemRegulationApi = (itemId, siteId) => {

    return API_URL + SITE_ITEM_REGULATION_API.replace('<itemId>', itemId.toString())
        .replace('<siteId>', siteId.toString());
}
