import { API_URL } from "../../../utils/apiConfig";
import { SearchFilters } from "./constants";

const ITEMS_API = 'regulations/items/';
const SERIES = 'series?searchText=';
const MARKET_API = 'market?searchText='
const OfFFICE_API = 'vendor/offices?searchText='
const COLLECTIVECLASS_API = 'collective-class?searchText='

export const FilterApi = () => {
    return API_URL + ITEMS_API;
}

export const GetFilterAPIUrl = (filter, seriesFilterKey) => {
    if (filter === SearchFilters.Series) {
        return API_URL + ITEMS_API + SERIES + seriesFilterKey;
    }
    else if (filter === SearchFilters.Market) {
        return API_URL + MARKET_API + seriesFilterKey;
    }
    else if (filter === SearchFilters.Office) {
        return API_URL + OfFFICE_API + seriesFilterKey;
    }
    else if (filter === SearchFilters.CollectiveClass) {
        return API_URL + COLLECTIVECLASS_API + seriesFilterKey;
    }
}