import { ActionTypes } from '../utils/constants';
import store from '../../../store/index';

export function ShowLoaderIndicator() {
    dispatchLoaderIndicator(true);
}

export function CloseLoaderIndicator() {
    dispatchLoaderIndicator(false);
}

const dispatchLoaderIndicator = (isOpenLoader) => {
    store.dispatch({
        type: ActionTypes.loaderOpenClose,
        isOpenLoader: isOpenLoader
    });
}