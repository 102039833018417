import { ActionTypes } from '../utils/constants'

const initialItemsState = {
    items: [],
    isfiltered: false
};

const itemReducer = (state = initialItemsState, action) => {
    switch (action.type) {
        case ActionTypes.items:
            return {
                ...state,
                items: action.items
            };
        case ActionTypes.isfiltered:
            return {
                ...state,
                isfiltered: action.isfiltered
            };
        default:
            return state;
    }
}

export default itemReducer;