import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import Dashboard from "../../modules/dashboard/components/dashboardIndex";

import { Button } from '@progress/kendo-react-buttons';
import logo from "../../utils/images/Logo.png";
import {
  TITLE, DASHBOARD, REGULATORY_MATRIX, COMMUNICATIONS, VENDOR_MANAGEMENT, TESTING, REGULATORY_MANAGEMENT
} from "../../utils/appStringConstants"
import AlertComponent from "../../shared-components/alerts/components/alertComponent";
import LoaderIndicatorComponent from "../../shared-components/page-loader-indicators/components/loader";
import { MapStateToHomeProps, MapDispatchToHomeProps } from "../utils/homePropMapping";
import Regulation from "../../modules/master/regulation/components/regulationIndex";

const HomePage = props => {

  const [show, setShow] = React.useState(false);
  const anchor = React.useRef(null);

  const { isAlertShow } = props;

  const handleClick = () => {
    setShow(!show);
  }

  const handleClickDashboard = event => {
    window.location = "/dashboard/Dashboard";
  };
  const handleClickRegulation = event => {
    window.location = "/regulation";
  };

  return (
    <React.Fragment>

      {
        isAlertShow && <AlertComponent />
      }

      <LoaderIndicatorComponent />

      <AppBar className="app-header">
        <AppBarSection className="title">

          <img src={logo} alt="logo" className="ashley-logo" />

          <h1 className="title" >{TITLE}</h1>
        </AppBarSection>

        <AppBarSpacer style={{ width: "2%" }} />

        <AppBarSection className="appbar-items">

          <ul>
            <li><span><Button look="flat" onClick={handleClickDashboard}>{DASHBOARD}</Button></span></li>
            <li><span><Button look="flat" onClick={handleClickRegulation}>regulation</Button></span></li>
            <li><span><Button look="flat" >{REGULATORY_MATRIX}</Button></span></li>
            <li><span><Button look="flat" >{COMMUNICATIONS}</Button></span></li>
            <li><span><Button look="flat">{VENDOR_MANAGEMENT}</Button></span></li>
            <li><span><Button look="flat" >{TESTING}</Button></span></li>
            <li><span><Button look="flat" >{REGULATORY_MANAGEMENT}</Button></span></li>
          </ul>
        </AppBarSection>

        <AppBarSpacer />

        <AppBarSection>
          <button
            className="k-button k-button-clear overflow-button"
            ref={anchor}
            onClick={handleClick}
          >
            <span className="k-icon k-i-menu" />
          </button>
          <Popup
            anchor={anchor.current}
            show={show}
            style={{ marginLeft: -10 }}
          >
            <div className="content">              
            </div>
          </Popup>

        </AppBarSection>
      </AppBar>

      <Router>

        <Switch>
          <Route path="/dashboard/Dashboard" component={Dashboard} />
          <Route path="/regulation" component={Regulation} />
        </Switch>
      </Router>



      <style>{`
            body {
                background: ##FFFFFF;
            }
            
            .title {
                font-size: 15px;
               
                padding-left: 12px;
                padding-top:5px;
                color: #696969;

            }
          
            ul {
                font-size: 20px;
                font-color:#D3D3D3;
                font-weight: bold;
                 list-style-type: none;
                padding: 0;
              margin: 0;
                display: contents;
            }
            li {
                margin: 0 0px;
                font-weight: bold;

              color: #696969;
            }
            li:hover {
                cursor: pointer;
                color: #000000;
            }
            .k-appbar-section {
                display: flex;
            }
            .k-button {
                padding: 0 8px;
            }
            .content li {
                margin: 0;
                padding: 6px 5px;
                color: #3f51b5;
            }
            .content ul {
                display: block;
            }
            .content {
                padding: 4px 8px;
            }
            .ashley-logo
            {
               max-width: 35%;
               marginRight: 2%;
               
            }
            .app-header
            {
             background: #D3D3D3;

            }
            @media only screen and (max-width: 600px) {
                .appbar-items,
                .user-actions,
                .k-avatar,                
                .k-appbar-separator {
                    display: none;
                }
                .overflow-button {
                    display: block;
                }
                .content ul li:last-child {
                    display: block;
                }
                .grid-items{
                     height: 60vh;
                     }
                     .search-btn
{
  margin-left: 55%;
}

            }
            /* Small devices (portrait tablets and large phones, 600px and up) */
            @media only screen and (min-width: 600px) {
                .appbar-items,
                .user-actions ,
                .k-avatar,
                .k-appbar-separator {
                    display: none;
                }
                .overflow-button {
                    display: block;
                }
                .content ul li:last-child {
                    display: none;
                }
                 .grid-items{
                   height: 65vh;
                 }
                 .search-btn
{
  margin-left: 55%;
}
            }
            /* Medium devices (landscape tablets, 768px and up) */
            @media only screen and (min-width: 768px) {
                .appbar-items,
                .user-actions ,
                .k-appbar-separator,
                .k-avatar {
                    display: block;
                }
                .overflow-button {
                    display: none;
                }
                .grid-items{
                   height: 70vh;
                    }
                    .search-btn
{
  margin-left: 55%;
}
            }
            /* Large devices (laptops/desktops, 992px and up) */
            @media only screen and (min-width: 992px) {
             
.grid-items{
  height: 75vh;
}
.search-btn
{
  margin-left: 55%;
}
 /* Larger devices (desktops, 1400px and up) */
            @media only screen and (min-width: 1400px) {
.grid-items{
  height: 86vh;
}
.search-btn
{
  margin-left:65%;
}
            }
            `}</style>

    </React.Fragment>
  );
}


export default connect(MapStateToHomeProps, MapDispatchToHomeProps)(HomePage);

