import React from 'react';
import { connect } from "react-redux";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import '@progress/kendo-react-animation'
import LoaderComponent from './loader';
const GridComponent = props => {

    const { gridColumns, total, detail, gridPageProperties, gridData, dataState } = props;
    const { onExpandChange, onDataStateChange } = props;

        return (
            <React.Fragment >
                <div className="row">
                    <div className="col s12">
                        {
                            <Grid className="grid-items"
                                pageable={gridPageProperties}
                                total={total}
                                data={gridData}
                                {...dataState}
                                onDataStateChange={onDataStateChange}
                                placeholder="Grid"
                                data-testid="Grid"
                                detail={detail}
                                expandField="expanded"
                                scrollable="scrollable"
                                onExpandChange={onExpandChange}
                            >

                                {
                                    gridColumns.map((column, index) => {
                                        if (column.cell) {
                                            return <GridColumn field={column.field} title={column.title} cell={column.cell} headerCell={column.headerCell} key={index} className="grid-header" style={{ 'font-size': '14px' }} />;
                                        }
                                        else {
                                            return <GridColumn field={column.field} title={column.title} key={index} headerCell={column.headerCell} />;
                                        }
                                    })
                                }

                            </Grid>
                        }
                    </div>
                </div>
            </React.Fragment >
        );

}

const mapStateProps = (state, ownProps) => {
    return {
        gridColumns: ownProps.gridColumns,
        expandField: ownProps.expandField,
        detail: ownProps.detail,
        gridProperties: ownProps.gridProperties
    };
};
const dispatchToGridMethods = (dispatch, ownProps) => {

    return {
        onExpandChange: (event) => ownProps.onExpandChange(event),
        onDataStateChange: (event) => ownProps.onDataStateChange(event)
    }
}
export default connect(mapStateProps, dispatchToGridMethods)(GridComponent);