
import { ActionTypes } from '../utils/constants'

const initialState = {
    isOpenLoader: false
};

const loaderIndicatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.loaderOpenClose:
            return {
                ...state,
                isOpenLoader: action.isOpenLoader
            };
        default:
            return state;
    }
}

export default loaderIndicatorReducer;