

export class AjaxAPI {

    async getMethod(url) {


        const fetchAttributes = {
            method: "GET",
            headers: {

                "Content-Type": "application/json"
            }
        };
        return await fetch(url, fetchAttributes);
    }

    async postMethod(url, param) {

        const fetchAttributes = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param),
        };
        return await fetch(url, fetchAttributes);
    }



    async putMethod(url, param) {

        const fetchAttributes = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param),
        };
        return await fetch(url, fetchAttributes);
    }

}
