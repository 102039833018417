
export const SearchEngineActionTypes = {
    isReset: "ISRESET",
    officeFilters : "OFFICEFILTERS",
    collectiveClassFilters : "COLLECTIVECLASSFILTERS",
    marketFilters : "MARKETFILTERS",
    seriesFilters : "SERIESFILTERS",
}

export const MAX_SERIES_SEARCH_TEXT = 2;

export const DELAY = 500;
export const SearchFilters = {
    Series: "Series",
    Market: "Market",
    Office: "Office",
    CollectiveClass:"CollectiveClass"

}