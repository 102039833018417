import { DispatchAlertClear } from '../actionCreators/alertAction'


export const MapStateToAlertProps = (state, ownProps) => {
    return {
        isSuccess: state.alertReducer.isSuccess,
        isError: state.alertReducer.isError,
        message: state.alertReducer.message
    };
};

export const MapDispatchToAlertProps = (dispatch, ownProps) => {
    return {
        DispatchAlertClear: () => dispatch(DispatchAlertClear())
    }
};
