import React, { useState, useEffect } from "react";
import { Tooltip } from '@progress/kendo-react-tooltip';
import TreeListComponent from '../../../shared-components/shared-controls/treeList';
import {
    RegulationResults, NOT_APPLICABLE, SITE_VENDOR,
    VENDOR_DETAIL_TITLE, ETD_TITLE, ETA_TITLE, OFFICE_TITLE, VENDOR_NAME_TITLE
} from '../../../utils/appStringConstants';
import { RegulationStatusIndicators } from '../utils/constants';
import { FetchComponentVendorRegulations } from '../actionCreators/siteVendorAction';
import { TreeListProperties } from "../utils/constants";

import {
    ShowLoaderIndicator, CloseLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';

const CategoryCell = (props, index) => {
    let strokeWidth = "0";
    let cellColor = RegulationStatusIndicators.defaultIndicator;
    let category = props.dataItem[props.field];
    if (props.field === "itemDetailId") {
    } else {

        if (category === RegulationResults.compliant) {
            cellColor = RegulationStatusIndicators.compliant;

        } else if (category === RegulationResults.expired) {
            cellColor = RegulationStatusIndicators.expired;
        } else if (category === RegulationResults.notUpdated) {
            cellColor = RegulationStatusIndicators.notUpdated;
        } else if (category === RegulationResults.comingDue) {
            cellColor = RegulationStatusIndicators.comingDue;
        }
        else if (category === RegulationResults.notApplicable) {
            cellColor = RegulationStatusIndicators.notApplicable;
            strokeWidth = "0.5";
        }
    }
    const shapeStyle = {

        fill: cellColor,
        stroke: "gray",
        'strokeWidth': strokeWidth,
        opacity: "1"
    }

    const textStyle = {
        fill: "gray",
        fontSize: "12",
        fontWeight: "bold"
    }

    return (
        <td >
            <svg width="80" height="20">
                <g>
                    <rect x="0" y="0" rx="10" ry="10" width="80" height="20"
                        style={shapeStyle} />
                    {
                        (category === RegulationResults.notApplicable) &&
                        <text x="31" y="15" style={textStyle} >{NOT_APPLICABLE}</text>
                    }
                </g>
            </svg>
        </td>
    );
};

const SiteVendorComponent = (props) => {


    const [columns, setColumns] = useState([]);
    const [siteVendorRegulations, setSiteVendorRegulations] = useState([]);
    const subItemsField = TreeListProperties.regulations;
    const [expanded, setExpanded] = useState([]);
    const [dataState, setDataState] = useState({
        sort: [],
        filter: []
    },
    );

    useEffect(() => {

        if (props.dataItem.details && props.dataItem.details?.regulations.length > 0) {
            ShowLoaderIndicator();
            const treeListColumns = [];
            treeListColumns.push({
                field: 'itemDetailName',
                title: SITE_VENDOR,
                expandable: true
            });
            props.dataItem.details.regulations.forEach((element, index) => {
                treeListColumns.push({
                    field: element.regulationId.toString(),
                    title: element.regulationName,
                    cell: CategoryCell,
                    className: element.regulationId.toString(),
                });
            });
            setColumns(treeListColumns);
            setSiteVendorRegulations(props.dataItem.details.siteVendorRegulations);
            CloseLoaderIndicator();
        }

    }, [props.dataItem.details]);


    const GetVendorNode = (cell, props) => {
        return (<td className={cell.props.className} colSpan="1">
            {(props.dataItem.itemDetailType === TreeListProperties.componentVendors) &&
                <span>
                    <span class="k-icon k-i-none"></span>
                    <span class="k-icon k-i-none"></span>
                </span>
            }
            <span
                title={props.dataItem.itemDetailName}
                accessKey={props.dataItem.id}
                className={`rd-${props.dataItem.itemDetailType}-${props.dataItem.itemDetailName}`}
            >
                {props.dataItem.itemDetailName}
            </span>
        </td>
        );
    }

    const handleExpandChange = async (e) => {
        let itemId = props.dataItem.itemId;

        const itemIndex = siteVendorRegulations.findIndex(val => e.dataItem.id === val.id);

        if (itemIndex >= 0 && (e.dataItem.itemDetailType === TreeListProperties.site) && !e.value) {
            ShowLoaderIndicator();
            const siteVendorItem = siteVendorRegulations[itemIndex];

            const value = await FetchComponentVendorRegulations(itemId, siteVendorItem.id, e.dataItem.itemDetailId);

            siteVendorRegulations.filter(x => x.id === e.dataItem.id)[0].regulations = value;

            setExpanded([...expanded, e.dataItem.id]);
            CloseLoaderIndicator();
        }
        else if ((e.dataItem.itemDetailType === TreeListProperties.site && e.value) || e.dataItem.itemDetailType === TreeListProperties.component || e.dataItem.itemDetailType === TreeListProperties.componentVendors) {
            setExpanded(e.value ?
                expanded.filter(id => id !== e.dataItem.id) :
                [...expanded, e.dataItem.id])
        }
    }

    const cellRender = (cell, props) => {
        if (props.expandable && !(props.dataItem.itemDetailType === TreeListProperties.finishedGoodVendor ||
            props.dataItem.itemDetailType === TreeListProperties.componentVendors)) {

            return (
                <cell.type
                    {...cell.props}
                    className={
                        cell.props.className ? `${cell.props.className} expandable2` : 'expandable2'
                    }
                />
            );
        }
        return GetVendorNode(cell, props);
    }

    const generateTooltipContent = (event) => {
        let index = siteVendorRegulations.findIndex(siteVendor => siteVendor.id === event.target.accessKey);
        let vendorName;
        let officeTitle;
        let eta;
        let etd;
        if (index == -1) {

            let parentId = event.target.accessKey.split('-');
            let parentIndex = siteVendorRegulations.findIndex(siteVendor => siteVendor.id === parentId[0]);

            if (parentIndex !== -1) {
                let childIndex = siteVendorRegulations.findIndex(siteVendor => siteVendor.id === parentId[1]);
                if (childIndex !== -1) {
                    let endChildIndex = siteVendorRegulations.findIndex(siteVendor => siteVendor.id === parentId[2]);
                    if (endChildIndex !== -1) {

                        vendorName = siteVendorRegulations[parentIndex].regulations[childIndex].regulations[endChildIndex].additionaDetails.vendorName;
                        officeTitle = siteVendorRegulations[parentIndex].regulations[childIndex].regulations[endChildIndex].additionaDetails.office || "";
                        eta = siteVendorRegulations[parentIndex].regulations[childIndex].regulations[endChildIndex].additionaDetails.eta || "";
                        etd = siteVendorRegulations[parentIndex].regulations[childIndex].regulations[endChildIndex].additionaDetails.etd || "";

                    }

                }
            }

        }


        else {

            vendorName = siteVendorRegulations[index].additionaDetails.vendorName;
            officeTitle = siteVendorRegulations[index].additionaDetails.office || "";
            eta = siteVendorRegulations[index].additionaDetails.eta || "";
            etd = siteVendorRegulations[index].additionaDetails.etd || "";
        }

        let content = (<React.Fragment>
            <u><b>{VENDOR_DETAIL_TITLE}</b></u>
            <br />
            <span>
                <span key={1}>{`${VENDOR_NAME_TITLE} : ${vendorName}`}<br /></span>
                <span key={2}>{`${OFFICE_TITLE}: ${officeTitle}`}<br /></span>
                <span key={3}>{`${ETA_TITLE}: ${eta}`}<br /></span>
                <span key={4}>{`${ETD_TITLE} : ${etd}`}<br /></span>
            </span>
        </React.Fragment>);
        return content;
    }


    return (
        <>
            <Tooltip anchorElement="target" content={generateTooltipContent}
                position="right" showCallout={true}>
                <TreeListComponent
                    subItemsField={subItemsField}
                    treeDataColumns={columns}
                    treeDataItems={siteVendorRegulations}
                    handleExpandChange={handleExpandChange}
                    expanded={expanded}
                    setDataState={setDataState}
                    dataState={dataState}
                    cellRender={cellRender}
                />
            </Tooltip>
        </>
    );
}

export default SiteVendorComponent;