
export const ActionTypes = {
    regulations: "REGULATIONS",
}

export const GridProperties = {
    startIndex: 0,
    endIndex: 10,
}

export const GridPageProperties = {
    buttonCount: 10,
    pageSizes: [10, 20, 50]
}


export const regulationGrid = {
    regulationName:"Regulation Name",
    expiryNotificationInDays:"Expiry Notification In Days",
    validityInDays:"Validity In Days"
}