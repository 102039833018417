import React, { useState } from "react";
import { Splitter } from '@progress/kendo-react-layout';
import Items from '../components/itemComponent';
import SearchEngine from '../../searchEngine/components/searchEngineComponent';

const Dashboard = props => {

    const [panes, setPanes] = useState([
        { size: '20%', collapsible: true, scrollable: false, collapsed: true }
    ]);

    const handlePaneChange = (event) => {
        setPanes(event.newState);
    }

    const handleFilterClick = () => {

        if (panes[0].collapsed === true) {
            setPanes([
                { size: '15%', collapsible: true, scrollable: false, collapsed: false }
            ]);
        }
        else {
            setPanes([
                { size: '15%', collapsible: true, scrollable: false, collapsed: true }
            ]);
        }
    }

    return (
        <React.Fragment>

            <Splitter
                style={{ height: "100%" }}
                panes={panes}

                keepMounted={true}
                onChange={handlePaneChange}


            >
                <div>
                    <SearchEngine />
                </div>
                <div style={{ margin: "0px", "overflowX": "hidden" }} >
                    <Items
                        handleFilterClick={handleFilterClick}
                    />
                </div>
            </Splitter>

        </React.Fragment>

    );
}
export default Dashboard;


