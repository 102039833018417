import { FetchItemsByFilter, DispatchSeriesFilters, DispatchMarketFilters, DispatchResetPagination,
    DispatchCollectiveClassFilters,DispatchOfficeFilters
 } from "../actionCreators/searchEngineAction"

export const MapStateToFilterProps = (state, ownProps) => {
    return {
        seriesFilters: state.searchEngineReducer.seriesFilters,
        marketFilters: state.searchEngineReducer.marketFilters,
        officeFilters: state.searchEngineReducer.officeFilters,
        collectiveClassFilters: state.searchEngineReducer.collectiveClassFilters,
        isReset: state.searchEngineReducer.isReset
    };
};

export const MapDispatchToFilterProps = (dispatch, ownProps) => {
    return {
        dispatchItemsByFilter: (filterData) => dispatch(FetchItemsByFilter(filterData)),
        dispatchSeriesFilters: (seriesFilters) => dispatch(DispatchSeriesFilters(seriesFilters)),
        dispatchMarketFilters: (marketFilters) => dispatch(DispatchMarketFilters(marketFilters)),
        dispatchCollectiveClassFilters: (collectiveClassFilters) => dispatch(DispatchCollectiveClassFilters(collectiveClassFilters)),
        dispatchOfficeFilters: (officeFilters) => dispatch(DispatchOfficeFilters(officeFilters)),
        dispatchResetPagination: (isReset) => dispatch(DispatchResetPagination(isReset))
    }
};