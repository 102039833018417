export const ITEMS = "itemSKU";
export const COMPLAINCE = "complianceStatusId";
export const COLLECTIVE_CLASS = "collectiveClassName";
export const MARKET = "marketName";
export const OFFICE = "office";
export const ETD = "etd";
export const ETA = "eta";
export const DESCRIPTION = "itemDescription";

export const ActionTypes = {
    items: "ITEMS",
    vendorDetail: "VENDOR",
    isVendorDetailDialogBoxShown: "ISVENDORDETAILDIALOGBOXSHOWN",
    vendorRegulations: "VENDORREGULATIONS",
    isfiltered: "ISFILTERED"
}

export const GridProperties = {
    startIndex: 0,
    endIndex: 10,
}

export const GridPageProperties = {
    buttonCount: 10,
    pageSizes: [10, 20, 50]
}

export const RegulationStatusIndicators = {
    defaultIndicator: "rgba(0, 0, 0, 0.12)",
    compliant: "rgb(55, 180, 0,0.32)",
    expired: "rgb(243, 23, 0, 0.32)",
    notUpdated: "#cccccc",
    comingDue: "#fffcbb",
    notApplicable: "transparent"
}

export const TreeListProperties = {
    site: "site",
    finishedGoodVendor: "vendor",
    component: "Item Component",
    componentVendors: "Vendor Item Component",
    regulations: "regulations"
}